import { useState } from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";

import "animate.css";

const navigation = [
  { name: "ABOUT", href: "/" },
  { name: "DEV PROJECTS", href: "/projects" },
  { name: "UPDATES", href: "/updates" },
  { name: "CONTACT", href: "contact" },
];

export default function Navbar() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <header className='bg-kats-tan'>
      <nav
        className='mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8'
        aria-label='Global'
      >
        <div className='flex lg:flex-1'>
          <a href='/' className='-m-1.5 p-1.5'>
            <span className='sr-only'>Kat Contreras</span>
            <h1 className='font-Bebas text-2xl text-black'>Kat Contreras</h1>
          </a>
        </div>
        <div className='flex lg:hidden'>
          <button
            type='button'
            className='-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-black'
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className='sr-only'>Open main menu</span>
            <Bars3Icon className='h-12 w-12' aria-hidden='true' />
          </button>
        </div>
        <div className='hidden lg:flex lg:gap-x-12'>
          {navigation.map((item) => (
            <a
              key={item.name}
              href={item.href}
              className='text-sm font-semibold leading-6 text-black'
            >
              {item.name}
            </a>
          ))}
        </div>
        <div className='hidden lg:flex lg:flex-1 lg:justify-end'>
          <a
            href='https://github.com/katcondev'
            target='_blank'
            rel='noreferrer'
            className='text-sm font-semibold leading-6 text-black uppercase'
          >
            GitHub <span aria-hidden='true'>&rarr;</span>
          </a>
        </div>
      </nav>
      <Dialog
        as='div'
        className='lg:hidden'
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className='fixed inset-0 z-100' />
        <Dialog.Panel className='animate__animated animate__fadeIn bg-hero bg-cover bg-opacity-70 fixed inset-y-0 right-0 z-10 w-full overflow-y-auto px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10'>
          <div className='flex items-center justify-between'>
            <a href='/' className='-m-1.5 p-1.5'>
              <span className='sr-only'>Kat Contreras</span>
              <h1 className='font-Bebas text-2xl text-black'>Kat Contreras</h1>
            </a>
            <button
              type='button'
              className='-m-2.5 rounded-md p-2.5 text-black'
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className='sr-only'>Close menu</span>
              <XMarkIcon className='h-12 w-12' aria-hidden='true' />
            </button>
          </div>
          <div className='mt-6 flow-root'>
            <div className='-my-6 divide-y divide-gray-500/10'>
              <div className='space-y-2 py-6'>
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className='-mx-3 block rounded-lg px-3 py-2 text-sm font-semibold leading-7 text-black hover:bg-kats-tan-li uppercase'
                  >
                    {item.name}
                  </a>
                ))}
              </div>
              <div className='py-6'>
                <a
                  target='_blank'
                  href='https://www.linkedin.com/in/katcontreras/'
                  rel='noreferrer'
                  className='-mx-3 block rounded-lg px-3 py-2.5 text-sm font-semibold leading-7 text-black hover:bg-kats-tan-li uppercase'
                >
                  LinkedIn
                </a>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}
