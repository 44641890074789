import React, { useState, useEffect } from "react";
import { fetchProjectsData } from "../projectsFirestoreService";
import Layout from "../components/Layout";
import Devs from "../components/Devs";
import { FadeIn, FadeInStagger } from "../components/FadeIn";

function Projects() {
  return (
    <Layout>
      <FadeInStagger>
        <div className='mx-auto max-w-7xl bg-kats-tan-li rounded-xl my-16 drop-shadow-md'>
          <FadeIn>
            <div className='mx-auto p-6 lg:px-8 '>
              <h1 className='text-4xl font-Bebas tracking-tight text-black'>
                Dev Projects
              </h1>

              <p className='mt-3 text-lg leading-8 text-black'>
                Explore my portfolio below to see examples of my work and the
                technologies I&apos;ve used to build them.
              </p>
            </div>
          </FadeIn>

          <Devs />
        </div>
      </FadeInStagger>
    </Layout>
  );
}

export default Projects;
