import React, { useState, useEffect } from "react";
import { fetchUpdatesData } from "../updatesFirestoreService";
import Layout from "../components/Layout";
import Tuts from "../components/Tuts";

function Updates() {
  const [updates, setUpdates] = useState([]);

  useEffect(() => {
    const fetchUpdate = async () => {
      try {
        const fetchedUpdate = await fetchUpdatesData();
        setUpdates(fetchedUpdate);
      } catch (error) {
        // Handle error or show error message
      }
    };

    fetchUpdate();
  }, []);
  return (
    <Layout>
      <div className='mx-auto max-w-6xl bg-kats-tan-li rounded-xl my-16 drop-shadow-md'>
        <div className='mx-auto px-6 pt-14 lg:px-8 py-6'>
          <h1 className='text-4xl font-Bebas tracking-tight text-black'>
            Project Updates
          </h1>
          <p className='mt-6 text-lg leading-8 text-black'>
            My current projects include building a membership database for
            Authority Collective that leverages generative AI search,
            co-producing a visuals-centric hybrid zine called Veer and
            participating in the 2023 Hackathon for Social Good hosted by Women
            Who Code.
          </p>
          <p className='mt-6 text-lg leading-8 text-black'>
            Select works in progress, digital media production and published
            works.
          </p>
        </div>

        <Tuts />
      </div>
    </Layout>
  );
}

export default Updates;
